* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
  font-family: "Poppins", sans-serif;
}

body {
  color: black;
  min-height: 100vh;
}

.title {
  color: white;
  font-size: 48px;
  /*opacity: 70%;*/
  padding: 0 1rem;
}
.mySwiper {
  display: flex;
  position: relative;
}

.sub-title {
  color: white;
  font-size: 36px;
  padding: 0 1rem;
}

.bar {
  display: flex;
  position: relative;
  width: 100%;
}
.nav-btns {
  width: 40%;
  display: flex;
  justify-content: flex-end;
}

.like-btn,
.chat-btn,
.home-btn,
.profile-btn,
.logo {
  background: none;
  color: white;
  cursor: pointer;
  border: none;
  padding: 1rem;
  font-size: 1rem;
}

.logo-img {
  background: none;
  height: 80px;
  padding-left: 10px;
  /*opacity: 70%;*/
}

.notif-num {
  font-family: inherit;
  border-radius: 50%;
  height: 19px;
  background-color: red;
  font-weight: 500;
  font-size: 0.8125em;
  width: 100%;
}


.like-btn:hover,
.chat-btn:hover,
.home-btn:hover,
.profile-btn:hover,
.logo:hover{
  text-shadow: 1px 1px 25px #121212;
  color: #fbfbfb;
}
/* .navl {
  background: none;
  color: white;
  border: none;
  font-size: 1rem;
}
.navl:hover {
  color: gray;
} */
.logo-container {
  flex: 3;
}

.normal {
  font-size: 1.2rem;
}
.padded {
  padding: 5px;
}
.logo {
  max-width: 20%; /* you can use % */
  height: auto;
}

.hero {
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("login_img.jpg");
  background-position: center;
  background-size: cover;
  position: absolute;
  align-items: center;
  justify-content: center;
  display: flex;
}

/*Dim background and have login pop up*/

.form-box-password-reset {
  width: 380px;
  display: flex;
  flex-direction: column;
  height: 300px;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 12% auto;
  text-align: center;
  transition: 0.5s;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.41);
  /*background: rgba(0, 0, 0, 0.3);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 5px;
  /*animation: fadein 1s;*/
}

.form-box {
  width: 380px;
  min-height: 365px;
  position: relative;
  margin: 12% auto;
  transition: 0.5s;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.41);
  /*background: rgba(0, 0, 0, 0.3);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 10px;
  /*animation: fadein 1s;*/
}

.form-box2 {
  width: 380px;
  height: 525px;
  position: relative;
  margin: 9% auto;
  transition: 0.5s;
  color: white;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.41);
  /*background: rgba(0, 0, 0, 0.3);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 5px;
}

.button-box {
  width: 55%;
  margin: 35px auto;
  position: relative;
  box-shadow: 0 0 20px 9px rgba(231, 227, 227, 0.3);
  border-radius: 30px;
}

.toggle-btn {
  padding: 10px 30px;
  cursor: pointer;
  background: transparent;
  border-radius: 30px;
  border: 0;
  outline: none;
  position: relative;
  color: white;
}

.toggle-right {
  padding: 10px 30px;
  cursor: pointer;
  background: linear-gradient(to right, #ff105f, #ffad06);
  border-radius: 30px;
  border: 0;
  outline: none;
  position: relative;
  color: black;
}

.btn {
  display: flex;
  width: 50%;
  height: 100%;
  border-radius: 30px;
  transition: 0.5s;
}

.input-group {
  position: relative;
  position: absolute;
  width: 280px;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flex-col {
  flex-direction: column;
}

.input-field {
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #ffffff;
  outline: none;
  background: transparent;
  position: relative;
  box-sizing: border-box;
  color: white;
  font-size: 1rem;
}

.submit-btn {
  width: 85%;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  margin: auto;
  background: linear-gradient(to right, #ff105f, #ffad06);
  border: 0;
  outline: none;
  border-radius: 30px;
  color: black;
  transition: all 0.25s ease-in-out;
}

.submit-btn:active {
  transform: scale(0.85);
  box-shadow: 3px 2px 25px 1px rgba(0, 0, 0, 0.25);
}

.swiper-wrapper {
  transform: translate3d(0,0,0)!important;
}

.instagram-btn {
  width: 85%;
  padding: 10px 30px;
  cursor: pointer;
  display: block;
  margin: 20px auto;
  background: linear-gradient(to right, #ff105f, #ffad06);
  border: 0;
  outline: none;
  border-radius: 30px;
  color: black;
}

.check-box {
  /* margin: 30px 10px 30px 0; */
  margin-top: 20px;
  cursor: pointer;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 20px;
  left: 80px;
  font-size: 12px;
  color: #e9c5c5;
  overflow: hidden;
}
.file-upload-button-wrap {
  position: relative;
  width: 100%;
  /* display: flex; */
}
.file-upload-button {
  margin-top: 10px;
  display: inline-block;
  padding: 12px 18px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #bb1f87;
  font-size: 12px;
  font-weight: bold;
  color: #fff;
}

.logmessage {
  position: absolute;
  font-size: 0.75em;
  top: 33%;
  background: red;
}

.numValid {
  position: absolute;
  font-size: 0.75em;
  bottom: 33%;
  background: green;
  color: white;
}

.hero span {
  color: #ffffff;
  font-size: 12px;
}

#login {
  left: 50px;
}

#register {
  left: 50px;
}

#submit-link:hover,
#submit-link:visited,
#submit-link:link,
#submit-link:active {
  text-decoration: none;
}

.bg {
  height: 100vh;
  width: 100%;
  background: linear-gradient(to left, #ff105f, #ffad06);
  background-position: center;
  background-size: cover;
  position: absolute;
  filter: brightness(80%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.dbg {
  height: 100vh;
  width: 100%;
  background: linear-gradient(to left, #ff105f, #ffad06);
  background-position: center;
  background-size: cover;
  position: absolute;
  filter: brightness(80%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/*Dim background and have login pop up*/

.home-card {
  width: 100%;
  height: 100%;
  position: relative;
  margin: auto;
  color: rgba(255, 255, 255, 0.56);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.23);
  /*background: rgba(255,255,255,0.1);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.home-header {
  height: 80%;
  color: white;
  /*margin: auto;*/
  left: 0;
  right: 0;
  text-align: center;
  /*padding: 10px;*/
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.home-header .online {
  font-size: 10px;
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin: 0% auto;
}

.home-start {
  margin: auto;
  /*overflow: hidden;*/
  transition: 0.5s;
  /*box-shadow: 0 0 20px 9px rgba(76, 255, 36, 0.12);*/
  cursor: pointer;
  background: linear-gradient(to right, #ff105f, #ffad06);
  color: white;
  text-shadow: 1px 1px #000000;
  border-style: none;
  border-radius: 5px;
  font-size: 20px;
  font-weight: bolder;
  padding: 0.5rem;
  width: 80%;
}

.home-start:hover {
  box-shadow: 0 0 20px 9px rgba(252, 255, 252, 0.35);
}

.fa-circle {
  color: rgba(7, 255, 7, 0.64);
}

.icon-flex {
  bottom: 4%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-card {
  position: relative;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  /*border-radius: 20px;*/
  transition-delay: 0.5s;
}

.icon-card1 {
  justify-content: flex-start;
  position: relative;
  width: 100%;
  border-radius: 20px;
  display: flex;
  align-items: center;
  transition-delay: 0.5s;
}

.icon-card .fa-user-clock {
  color: white;
  opacity: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 0.5s;
}

.icon-card:hover .fa-user-clock {
  left: 25%;
}

.icon-card .fa-sign-out-alt {
  color: white;
  opacity: 70%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;
  transition: 0.5s;
}

.icon-card:hover .fa-sign-out-alt {
  top: 0%;
}

.icon-card .content {
  position: relative;
  width: 100%;
  padding: 20px 20px 20px 40px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  color: white;
  left: 10%;
}

.icon-card1 .content {
  position: relative;
  width: 100%;
  padding: 20px 20px 20px 40px;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  color: white;
  left: 10%;
}

.icon-card1 #datesleft-card {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
  cursor: default;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  /*background: rgba(0, 0, 0, 0.3);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
}

#date-reset {
  color: white;
  font-weight: bolder;
  border: 1px solid rgba(21, 20, 20, 0.3);
  border-radius: 10px;
  background: rgba(21, 20, 20, 0.3);
  /*background: rgba(0, 0, 0, 0.3);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 2px 5px;
  letter-spacing: 5px;
}

#signout-card {
  position: relative;
  width: 60%;
  transition: 0.5s;
  /*top: 20px;*/
  cursor: pointer;
  display: block;
  align-items: center;
  margin: auto;
  text-align: center;
  border: 0;
  padding: 10px 30px;
  background: white;
  outline: none;
  border-radius: 30px;
  color: red;
  font-size: 20px;
}

.icon-card:hover #signout-card {
  position: relative;
  text-align: center;
  width: 75%;
  transition: 0.5s;
}

.exit-icon {
  color: rgba(255, 255, 255, 0.56);
  padding: 5px;
}



@keyframes fadein {
  from {
    filter: brightness(55%);
  }
  to {
    filter: brightness(100%);
  }
}

.input2 {
  width: 100%;
  padding: 10px 0;
  margin: 5px 0;
  border-left: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 1px solid #fff;
  outline: none;
  background: transparent;
  position: relative;
  color: white;
}

::placeholder {
  color: white;
}

/*questions.js*/
.question {
  position: relative;
  text-align: center;
  font-size: 2rem;
  color: white;
  word-spacing: 10px;
}

.answers {
  height: 100%;
  width: 70%;
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
}

#q3 {
  width: 50%;
}

.vert-card {
  min-width: 25%;
  height: 50%;
  overflow: hidden;
  position: relative;
  margin: auto;
  color: rgb(255, 255, 255);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  text-align: center;
  font-size: 3rem;
}

#q3 .vert-card {
  width: 40%;
}

.vert-card h2 {
  font-size: 2rem;
  /* min-font-size: 10px; */
}

.vert-card:hover {
  cursor: pointer;
  /* bottom: 5%; */
  transition: 0.5s;
  box-shadow: 0 5px 45px rgba(252, 251, 246, 0.94);
  height: 55%;
}

.fa-plus,
#a-heart,
fa-male,
fa-female {
  font-size: 5rem;
}

.vert-center {
  margin: auto;
  position: relative;
  /* top: 12%; */
  color: white;
  /* height: 100%; */
  width: 100%;
}

.fa-arrow-left {
  position: relative;
  color: rgba(255, 255, 255, 0.71);
  padding: 2%;
  font-size: 2rem;
  opacity: 85%;
  transition: 0.5s;
  width: 100%;
}

.fa-arrow-left:hover {
  transition: 0.5s;
  color: white;
  opacity: 100%;
}

.likes-hl {
  position: relative;
  text-align: center;
  font-size: 2rem;
  color: white;
  top: 10%;
}

.likes-container {
  position: relative;
  height: 100%;
  max-width: 70%;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.swiper-slide {
  position: relative;
  height: 300px;
  width: 300px;
  background: center;
  background-size: cover;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  background: transparent;
  perspective: 1000px;
}

.flip-card-inner {
  height: 100%;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

.front-card {
  /* backface-visibility: hidden; */
  height: 100%;
  width: 100%;
  position: absolute;
}

.swiper-slide img {
  position: relative;
  width: 100%;
  height: 100%;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.23);
  backface-visibility: hidden;
  transition: 0.1s;
}

.swiper-slide:hover .flip-card-inner .back-card{
  /* transform: rotateY(180deg); */
  visibility: visible;
}

.swiper-slide:hover .flip-card-inner .front-img {
  opacity: 0%;
  transition-duration: 0;
}

.swiper-slide:hover .flip-card-inner .liked-time {
  opacity: 0%;
  transition-duration: 0;
}

/* .swiper-slide:checked .flip-card-inner {
  transform: rotateY(180deg);
} */

.swiper-slide:checked .flip-card-inner .front-img {
  opacity: 0%;
  transition-duration: 0;
}

.swiper-slide:checked .flip-card-inner .liked-time {
  opacity: 0%;
  transition-duration: 0;
}

.back-card {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.23);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  color: white;
  /* transform: rotateY(180deg); */
  transition: 0s;
  backface-visibility: hidden;
  visibility: hidden;
  height: 100%;
  width: 100%;
  position: absolute;
  font-size: 0.65rem;
  text-align: center;
  /* padding: 10%; */
  display: block;
}
.back-card:hover {
  visibility: visible;
}

/* .back-card h1 {
} */

.back-card h2 {
  padding: 2%;
}

.back-card button {
  padding: 2%;
  width: 65%;
  position: relative;
  margin-top: 5%;
  cursor: pointer;
  background: rgba(10, 101, 239, 0.74);
  color: white;
  border-style: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: 0.1s;
}

.back-card button:hover {
  width: 80%;
  font-size: 1.2rem;
  transition: 0.5s;
}

.back-card span {
  position: relative;
  font-size: 0.8rem;
  margin-bottom: 10%;
}

.back-card img {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  margin: 10% auto;
  border: 4px solid transparent;
}

.swiper-button-prev {
  transform: translateX(-80px);
}

.swiper-button-next {
  transform: translateX(80px);
}

.swiper-slide-active:hover img {
  position: relative;
}

.swiper-slide .liked-time {
  color: white;
  position: absolute;
  right: 5px;
  bottom: 10px;
  font-size: 0.5rem;
  border: 1px solid white;
  border-radius: 10px;
  background: rgba(16, 15, 15, 0.52);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 5px;
  backface-visibility: hidden;
  transition: 0.5s;
}

/*DATE PAGE*/

.datepage {
  height: 100%;
  width: 100%;
  display: flex;
  margin: auto;
  position: relative;
  align-items: center;
  justify-content: center;
}
.responsive-date-page {
  height: 80%;
  width: 100%;
  overflow-y: scroll;
  display: flex;
  /*margin: auto;*/
  position: relative;
  /*align-items: flex-start;*/
  justify-content: center;
}

.remote_video_ {
  margin-top: 20px!important;
}
.local_stream {
  margin-top: 25px!important;
}

.screen {
  width: 65%;
  height: 75%;
  background: black;

  border: 1px solid white;
  border-radius: 10px;
}

.report-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  transition-delay: 0.5s;
  top: 87%;
  right: 5%;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  /* width: 20%;
  height: 20%; */
}

.fa-flag {
  position: relative;
  font-size: 3rem;
  color: red;
  padding: 3%;
  bottom: 20%;
  transition: 0.5s;
}

.fa-flag:hover {
  opacity: 100%;
}

/* #report-card {
  position: relative;
  transition: 0.5s;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;
  display: block;
  align-items: center;
  margin: auto;
  text-align: center;
  border: 0;
  background: white;
  outline: none;
  border-radius: 30px;
  color: red;
  bottom: 30%;
  width: 50%;
} */

/* .report-icon:hover #report-card {
  opacity: 1;
  visibility: visible;
  transition: 0.5s;
  cursor: pointer;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  background: white;
  bottom: 10%;
} */

.report-icon:hover .fa-flag {
  transition: 0.5s;
  cursor: pointer;
}

.right-panel {
  width: 25%;
  height: 75%;
  border: 2px solid white;
  padding: 1px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  border: 1px solid white;
  border-radius: 10px;
  background: rgba(16, 15, 15, 0.52);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  transition: 1s;
}

.right-top {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  color: white;
  max-height: 60%;
  text-align: center;
  font-size: 1.35rem;
  font-weight: bold;
  padding: 4%;
}

.right-top h1 {
  position: relative;
  color: white;
  text-shadow: 1px 1px 2px #60b2de, 0 0 25px #2591f1, 0 0 5px #1a60e7;
  box-shadow: 0 0 20px 9px rgba(34, 164, 229, 0.58);
  border-radius: 10px;
  background-image: linear-gradient(315deg, #eec0c6 0%, #7ee8fa 74%);
  padding: 4%;
  font-size: 1.5rem;
}

.icebreakers {
  padding: 4%;
  font-size: 0.9rem;
  position: relative;
  text-shadow: 1px 1px 2px #60b2de, 0 0 25px #2591f1, 0 0 5px #1a60e7;
  height: 100%;
  overflow: hidden;
}

.icebreakers h2 {
  padding: 5%;
}

.right-bot {
  position: relative;
  min-height: 40%;
  max-height: 40%;
  text-align: center;
  color: white;
  width: 100%;
  display: flex;
}

.solid {
  border-top: 1px solid white;
  width: 65%;
  margin: auto;
}

/*TIMER*/

.base-timer {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  /*margin: auto;*/
  align-items: center;
  justify-content: center;
  /*top: 20%;*/
}

.base-timer__svg {
  transform: scaleX(-1);
}

.base-timer__circle {
  fill: none;
  stroke: none;
}

.base-timer__path-elapsed {
  stroke-width: 7px;
  stroke: transparent;
}

.base-timer__path-remaining {
  stroke-width: 7px;
  stroke-linecap: round;
  transform: rotate(90deg);
  transform-origin: center;
  transition: 1s linear all;
  fill-rule: nonzero;
  stroke: currentColor;
}

.base-timer__path-remaining.green {
  color: rgb(65, 184, 131);
}

.base-timer__path-remaining.orange {
  color: orange;
}

.base-timer__path-remaining.red {
  color: red;
}

.base-timer__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 2em;
  width: 100%;
  /*bottom: 50%;*/
}

/*MODAL STYLING*/

.modal-bg {
  z-index: 2;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-wrapper {
  width: 800px;
  height: 500px;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.2);
  background: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  line-height: 1.8;
  color: #141414;
  border-radius: 10px;
}

.modal-header {
  text-align: center;
  padding-top: 50px;
}

.modal-header h1 {
  font-size: 2.5rem;
  padding-bottom: 25px;
}

.modal-header h2 {
  font-size: 2rem;
}

.fa-thumbs-up {
  color: #0b7216;
  padding: 40px;
  transition: 0.5s;
  font-size: 10rem;
  cursor: pointer;
}

.fa-thumbs-down {
  transform: scaleX(-1);
  color: #ff105f;
  padding: 40px;
  transition: 0.5s;
  font-size: 10rem;
  cursor: pointer;
}

.poll {
  position: relative;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 5%;
  gap: 15%;
}

.poll h1 {
  font-size: 1.5rem;
  padding-bottom: 15px;
}

.poll i {
  padding: 40px;
  transition: 0.5s;
  font-size: 5rem;
}

.poll-a {
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.23);
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  transition: 0.5s;
  color: black;
}

.poll-a:hover {
  cursor: pointer;
  transition: 0.5s;
  box-shadow: 0 5px 45px rgba(239, 99, 43, 0.94);
}

#file-upload {
  visibility: hidden;
  position: absolute;
}

#estrellas {
  position: relative;
  align-items: center;
  justify-content: center;
  width: 520px;
  height: 300px;
  margin: 0 auto;
  font-size: 6rem;
  color: white;
}
#estrellas span {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-left: -35px;
  line-height: 100px; /* height*2 - font-size */
  text-align: center;
  transform-origin: bottom center;
  animation: spin 3s linear 2;
}

#estrellas span:nth-of-type(1) {
  transform: rotate(180deg);
  opacity: 10%;
}

#estrellas span:nth-of-type(2) {
  transform: rotate(135deg);
  opacity: 20%;
}

#estrellas span:nth-of-type(3) {
  transform: rotate(90deg);
  opacity: 30%;
}

#estrellas span:nth-of-type(4) {
  transform: rotate(45deg);
  opacity: 40%;
}

#estrellas span:nth-of-type(5) {
  transform: rotate(0deg);
  opacity: 50%;
}

#estrellas span:nth-of-type(6) {
  transform: rotate(-45deg);
  opacity: 60%;
}

#estrellas span:nth-of-type(7) {
  transform: rotate(-90deg);
  opacity: 70%;
}

#estrellas span:nth-of-type(8) {
  transform: rotate(-135deg);
  opacity: 80%;
}

#estrellas span:nth-of-type(9) {
  transform: rotate(-180deg);
  opacity: 90%;
}

@keyframes spin {
  30% {
    transform: rotate(-360deg);
  }
}

.chat-container {
  display: grid;
  grid-template-columns: 2fr;
  width: 70%;
  height: 70%;
  position: relative;
  top: -15%;
  margin: 9% auto;
  color: rgba(255, 255, 255, 0.56);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.7);
  /*background: rgba(255,255,255,0.1);*/
  backdrop-filter: blur(4px);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin-bottom: 0px;
  margin-top: 150px;
}

html {
  color: #282828;
}

/* Current chat profile pic and username */
#chat-title {
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 1;
  grid-row-end: 2;
  overflow: hidden;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding: 10px 0 10px 0;
  font-weight: bold;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  background-color: #3d160d;
}

#chat-title p,
.profile-pic {
  left: 50px;
}

#chat-title p {
  /*top: -17px;*/
  display: inline-block;
  /* position: relative; */
  /*bottom: 30%;*/
  /*padding-left: 10px;*/
  color: white;
}

.profile-pic {
  width: 70px;
  height: 70px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  display: inline;
  margin: 0 auto;
  margin-left: -25%;
}

/* "Messages" logo */
#message-title {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 1;
  grid-row-end: 2;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  font-size: 70%;
}

/*  Current Conversation */
#chat-box {
  grid-column-start: 1;
  grid-column-end: 6;
  grid-row-start: 2;
  grid-row-end: 5;
  display: flex;
  flex-direction: column-reverse;
  padding: 60px 20px 80px 20px;
}

::-webkit-scrollbar {
  display: none;
}

.message-row {
  display: grid;
  grid-template-columns: 70%;
  margin-bottom: 20px;
}

.message-own {
  /* justify-content: end;
  justify-items: end; */
  float: right;
}

.their-message {
  /* justify-items: start; */
  float: left;
}

.message-text {
  padding: 9px 14px;
  font-size: 80%;
  margin-bottom: 5px;
  font-weight: bold;
}

.messageBottom {
  font-size: 70%;
  color: lightgrey;
}

.their-message .message-text {
  background: linear-gradient(to bottom right, #fdff9b, #fdff9b, #6e6d6c);
  color: black;
  border-radius: 14px 14px 14px 0;
  width: auto;
}

.message-own .message-text {
  background: linear-gradient(to bottom right, #ff105f, #ff105f, #ffad06);
  color: white;
  border-radius: 14px 14px 0 14px;
}

.noConversationText {
  position: absolute;
  top: 10%;
  font-size: 50px;
  color: lightgrey;
  cursor: default;
}

/* List of other Conversations */
#conversation-list {
  grid-column-start: 8;
  grid-column-end: 12;
  grid-row-start: 2;
  grid-row-end: 10;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow: 0 5px 45px rgba(0, 0, 0, 0.1);
  overflow-y: scroll;
}

#conversation-list::-webkit-scrollbar {
  display: none;
}

.conversation {
  display: grid;
  grid-template-columns: 40px 1fr max-content;
  font-size: 80%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px 20px 20px 15px;
  color: white;
  background: transparent;
  justify-content: center;
  align-items: center;
}

.conversation.active,
.conversation:hover {
  display: flex;
  background: dimgray;
}

.conversation:hover {
  cursor: pointer;
}

.conversation > img {
  grid-row: span 5;
  width: 40px;
  height: 40px;
  position: relative;
  /*overflow: hidden;*/
  border-radius: 50%;
  display: inline;
  margin: 0 auto;
  /*margin-left: -25%;*/
}

.title-text {
  font-weight: bold;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.conversation-time {
  font-size: 85%;
}

.conversation-message {
  grid-column: span 2;
  padding-left: 5px;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

/* Message Box */
#chat-form {
  position: fixed;
  bottom: 0px;
  grid-column-start: 1;
  grid-column-end: 8;
  grid-row-start: 5;
  grid-row-end: 10;
  border-top: 2px solid rgba(255, 255, 255, 0.5);
  padding-top: 5px;
  padding-left: 0;
  padding-bottom: 5px;
  width: 100%;
  background-color: #3d160d;
}

/* Where message is typed*/
.textbox {
  outline: none;
  padding: 5px;
  border: solid;
  color: white;
  background: transparent;
  border-radius: 20px;
  font-size: 90%;
  width: 65%;
}

/* Send Message Button*/
.send {
  float: right;
  text-align: center;
  padding-right: 10px;
  padding-top: 5px;
}

.chat-start {
  width: 70%;
  height: 30%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.5s;
  margin: 5%;
  margin-left: 15%;
  /*box-shadow: 0 0 20px 9px rgba(76, 255, 36, 0.12);*/
  cursor: pointer;
  background: #ff105f;
  color: white;
  border-style: none;
  border-radius: 5px;
  font-size: 20px;
}

.chat-start:hover {
  box-shadow: 0 0 20px 9px rgba(252, 255, 252, 0.35);
  font-size: 22px;
}

#messages-word {
  padding-left: 5%;
  border-bottom: 2px solid dodgerblue;
}

.estrellas {
  position: relative;
  width: 300px;
  height: 180px;
  bottom: 180px;
  margin: 0 auto;
  font-size: 3rem;
  color: white;
}
.estrellas span {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
  /*margin-left: -35px;*/
  line-height: 100px; /* height*2 - font-size */
  text-align: center;
  transform-origin: bottom center;
  animation: spin 1.2s linear infinite;
}

.estrellas span:after {
  content: " ";
  display: block;
  position: absolute;
  border-radius: 20%;
  background: #fff;
}

.estrellas span:nth-of-type(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}

.estrellas span:nth-of-type(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}

.estrellas span:nth-of-type(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}

.estrellas span:nth-of-type(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}

.estrellas span:nth-of-type(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}

.estrellas span:nth-of-type(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}

.estrellas span:nth-of-type(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}

.estrellas span:nth-of-type(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}

.estrellas span:nth-of-type(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}

.estrellas span:nth-of-type(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}

.estrellas span:nth-of-type(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}

.estrellas span:nth-of-type(12) {
  transform: rotate(330deg);
  animation-delay: -0s;
}

/* Different media queries */
@media (max-width: 600px) {
  /* Navabr  */
  .title {
    font-size: 40px;
  }

  .like-btn,
  .chat-btn,
  .home-btn,
  .profile-btn,
  .logo {
    padding: 0.5rem;
    font-size: 0.8rem;
    position: relative;
    /*opacity: 70%;*/
  }

  .logo-img {
    background: none;
    height: 60px;
    padding-left: 10px;
    /*opacity: 70%;*/
  }

  /* Responsive cards */
  .vert-card {
    width: 100%;
    margin: 10px 0px;
    /* margin-left: 0;
  margin-right: 0; */
  }

  /* Form part */
  .form-box {
    width: 85%;
  }
  .form-box2 {
    width: 85%;
  }
  .button-box {
    width: 65%;
  }
  .input-group {
    width: 78%;
  }

  .submit-btn {
    width: 60%;
  }
  .answers {
    flex-direction: column;
  }

  /* Chat Page */

  .total-chat {
    position: relative;
  }
  .chat-container {
    width: 100%;
    margin-top: 0px;
    top: 0%;
  }

  #chat-title p {
    /*top: -17px;*/
    display: inline-block;
    font-size: 15px;
    /* position: relative; */
    /*bottom: 30%;*/
    /*padding-left: 10px;*/
    color: white;
  }
}

@keyframes spin {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}

.loading-tag {
  color: #fff;
  text-align: center;
  position: relative;
  top: 15%;
}
/* 

/* COMMON CSS */
.full-screen {
  height: 100vh;
}

.centering {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-bold {
  font-weight: bold;
}
.pd {
  padding: 10px;
}
.pd-top {
  padding-top: 10px;
}

.pd-left {
  padding-left: 10px;
}

.pd-right {
  padding-right: 10px;
}

.pd-bottom {
  padding-bottom: 10px;
}

.mg {
  margin: 10px;
}

.mg-left {
  margin-left: 10px;
}
.mg-top {
  margin-top: 10px;
}
.mg-right {
  margin-right: 10px;
}
.mg-bottom {
  margin-bottom: 10px;
}
